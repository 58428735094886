import * as React from "react";
import CryptoExchangeSummaryCard from "../../components/Modules/tools/exchange-comparison/crypto-exchange-summary-card";
import { graphql } from "gatsby";
import { ContentfulCryptoExchange } from "../../common/types/contentful-models";
import Logo from "@components/Core/Layout/Logo";

export const igCryptoExchangesQuery = graphql`
  query IgPageQuery {
    allContentfulCryptoExchange {
      nodes {
        name
        registerBonus
        exchangeFeatures
        complexity
        numberTradableCoins
        feeBuyCryptoInPercent
        feeSellCryptoInPercent
        surchargeInPercent
        registerBonusInEuro
        countryUnicode
        companyLogo {
          id
          gatsbyImageData(height: 32)
        }
        referralLinks
      }
    }
  }
`;
type Props = {
  data: {
    allContentfulCryptoExchange: {
      nodes: ContentfulCryptoExchange[];
    };
  };
};

// Instagram LandingPage
const IgPage: React.FC<Props> = ({ data }) => {
  const cryptoExchanges = data.allContentfulCryptoExchange.nodes.filter((exchange) => !!exchange.name);
  return (
    <div className="max-w-screen-sm mx-auto">
      <div className="p-4 grid grid-cols-1 gap-2">
        <h1 className="text-3xl text-center font-bold tracking-tight text-einfach-crypto-primary">
          <div className="flex justify-center items-center">
            <Logo className="w-1/6"/>
            <span>Krypto-Börsen</span>
          </div>
        </h1>
        {cryptoExchanges.map((cryptoExchange) => (
          <CryptoExchangeSummaryCard key={"cryptoExchangeSummaryCard-" + cryptoExchange.name} contentfulCryptoExchange={cryptoExchange} />
        ))}
      </div>
    </div>
  );
};

export default IgPage;
