import * as React from "react";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { GiPayMoney } from "@react-icons/all-files/gi/GiPayMoney";
import { GiReceiveMoney } from "@react-icons/all-files/gi/GiReceiveMoney";
import { RiLineChartLine } from "@react-icons/all-files/ri/RiLineChartLine";
import { RiCoinsLine } from "@react-icons/all-files/ri/RiCoinsLine";
import { MdWeb } from "@react-icons/all-files/md/MdWeb";
import { MdSmartphone } from "@react-icons/all-files/md/MdSmartphone";
import { FaWallet } from "@react-icons/all-files/fa/FaWallet";
import { AiFillSafetyCertificate } from "@react-icons/all-files/ai/AiFillSafetyCertificate";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { getRandomStringFromList } from "../../../../common/helpers/list-helper";
import { ContentfulCryptoExchange } from "../../../../common/types/contentful-models";
import { CryptoExchangeFeature } from "../../../../common/types/models";

type Prop = {
  contentfulCryptoExchange: ContentfulCryptoExchange;
};

const CryptoExchangeSummaryCard: React.FC<Prop> = ({ contentfulCryptoExchange }) => {
  const {
    name,
    countryUnicode,
    feeBuyCryptoInPercent,
    feeSellCryptoInPercent,
    surchargeInPercent,
    companyLogo,
    numberTradableCoins,
    exchangeFeatures,
    registerBonusInEuro,
    referralLinks,
  } = contentfulCryptoExchange;
  const cryptoExchangeFeatures = [];
  if (!!exchangeFeatures && exchangeFeatures.length > 0) {
    for (const feature of exchangeFeatures) {
      switch (feature) {
        case CryptoExchangeFeature.WEB_APP:
          cryptoExchangeFeatures.push(<MdWeb key="mdWebIcon" />);
          break;
        case CryptoExchangeFeature.MOBILE_APP:
          cryptoExchangeFeatures.push(<MdSmartphone key="mdSmartphone" />);
          break;
        case CryptoExchangeFeature.WALLET_SERVICE:
          cryptoExchangeFeatures.push(<FaWallet key="faWallet" />);
          break;
        case CryptoExchangeFeature.TWO_FACTOR_AUTHENTICATION:
          cryptoExchangeFeatures.push(<AiFillSafetyCertificate key="aiFillSafetyCertificate" />);
          break;
        default:
          break;
      }
    }
  }
  return (
    <a className="text-white text-xs" href={getRandomStringFromList(referralLinks)}>
      <div className="grid grid-cols-12 gap-2 rounded-md bg-einfach-crypto-primary p-4 flex items-center">
        <div className="col-span-6 flex items-center">
          <GatsbyImage image={getImage(companyLogo)} alt={name} />
          <div className="pl-2 flex items-center text-lg">{getUnicodeFlagIcon(countryUnicode)}</div>
        </div>
        <div className="col-span-6 flex justify-between">
          <div className="flex flex-col items-center">
            <GiPayMoney className="text-xl" />
            <div>{feeBuyCryptoInPercent}%</div>
          </div>
          <div className="flex flex-col items-center">
            <GiReceiveMoney className="text-xl" />
            <div>{feeSellCryptoInPercent}%</div>
          </div>
          <div className="flex flex-col items-center">
            <RiLineChartLine className="text-xl" />
            <div>{surchargeInPercent}%</div>
          </div>
          <div className="flex flex-col items-center">
            <RiCoinsLine className="text-xl" />
            <div>{numberTradableCoins}</div>
          </div>
        </div>
        <div className="col-span-6 flex text-lg">{cryptoExchangeFeatures}</div>
        <div className="col-span-6">{registerBonusInEuro ? registerBonusInEuro + " Euro" : "-"}</div>
      </div>
    </a>
  );
};

export default CryptoExchangeSummaryCard;
