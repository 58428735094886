import { ContentfulLongDescriptionText } from './contentful-models';

export interface BlogCardOverviewModel {
    title: string;
    slug: string;
    description?: ContentfulLongDescriptionText;
}

export enum CryptoExchangeFeature {
    WEB_APP = 'WEB_APP',
    MOBILE_APP = 'MOBILE_APP',
    WALLET_SERVICE = 'WALLET_SERVICE',
    TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION'
}

export enum CryptoExchangeComplexity {
    SIMPLE = 'Einfach',
    COMPLEX = 'Komplex'
}

export enum CryptoCurrency {
    BTC = 'BTC',    // Bitcoin
    ETH = 'ETH',    // Ethereum
    XRP = 'XRP',    // Ripple
    BCH = 'BCH',    // BitcoinCash
    LTC = 'LTC'     // Litecoin
}